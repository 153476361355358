<template>
  <!-- 待办工单 -->
  <div class="box-card" style="text-align: left;margin-top: 20px;">
    <!-- 查询表单 -->
    <el-form :inline="true" size="small" label-position="right" class="query_form" :model="queryForm" ref="queryFormRef"
      @submit.native.prevent>
      <el-form-item label="日期：">
        <el-date-picker v-model="date" type="daterange" value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="['00:00:00', '23:59:59']" range-separator="至" start-placeholder="请选择开始日期"
          end-placeholder="请选择结束日期" @blur="onSubmit">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="工单类型：">
        <el-select popper-class="my-select" clearable v-model="queryForm.exception_type" placeholder="请选择工单类型"
          @clear="queryForm.exception_type = null" @change="onSubmit()">
          <!-- 工单类型 1：巡检任务 2：视频巡检 3：事件上报 10:全部 -->
          <!-- <el-option label="全部" :value="null"></el-option> -->
          <el-option label="巡检任务" :value="1"></el-option>
          <el-option label="视频巡检" :value="2"></el-option>
          <el-option label="事件上报" :value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="状态：">
        <el-select popper-class="my-select" clearable v-model="queryForm.deal_status" placeholder="请选择任务状态"
          @clear="queryForm.deal_status = null" @change="onSubmit()">
          <!-- 状态 1：全部 2：待处理 3:已处理 -->
          <!-- <el-option label="全部" :value="0"></el-option> -->
          <el-option label="待处理" :value="1"></el-option>
          <el-option label="已完成" :value="2"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="巡检人员：">
        <el-input maxlength="30" v-model="queryForm.inspector_name" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.inspector_name = validSpace(e))" placeholder="请输入巡检人员"></el-input>
      </el-form-item>
      <el-form-item label="维修人员：">
        <el-input maxlength="30" v-model="queryForm.repairer_name" @keyup.enter.native="onSubmit()"
          @input="(e) => (queryForm.repairer_name = validSpace(e))" placeholder="请输入维修人员"></el-input>
      </el-form-item>
      <el-form-item>
        <lbButton icon="chaxun" :fill="true" @click="onSubmit()">查询</lbButton>
      </el-form-item>
    </el-form>
    <le-card title="待办工单">
      <template slot="heard-right">
        <div class="right">
          <lbButton type="err" icon="shanchu1" @click="batchDel" v-if="isShowBtn('af13b4a56828458380d9cc88')">删除
          </lbButton>
        </div>
      </template>
      <div>
        <!-- 查询表单 -->
        <!-- 表格 -->
        <!-- 使用插件生产表格 -->
        <!-- <avue-crud :data="tableData" :option="option" :page.sync="page" @selection-change="selectionChange"
          @size-change="sizeChange" @current-change="currentChange">
          <template slot-scope="{row}" slot="exception_type">
            {{ exceptionType(row.exception_type) }}
          </template>
          <template slot-scope="{row}" slot="is_deal">
            <span :style="'color:' + orderDealStatusColor(row.is_deal)">
              {{ orderDealStatus(row.is_deal) }}
            </span>
          </template>
          <template slot-scope="{row}" slot="create_time">
            {{ $moment(row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
          </template>
          <template slot-scope="{row}" slot="menu">
            <lbButton type="succes" icon="chakan" hint="查看" @click="lookBacklogOrder(row.Id)"></lbButton>
          </template>
        </avue-crud> -->
        <el-table ref="multipleTable" :data="tableData" tooltip-effect="dark" style="width: 100%" border stripe
          @selection-change="handleSelectionChange" height="560">
          <el-table-column type="selection" width="55" align="center" header-align="center"></el-table-column>
          <el-table-column prop="exception_note" label="异常说明"></el-table-column>
          <el-table-column label="工单类型">
            <template slot-scope="scope">
              {{ exceptionType(scope.row.exception_type) }}
            </template>
          </el-table-column>
          <el-table-column prop="inspector_name" label="巡检人员"></el-table-column>
          <el-table-column prop="repairer_name" label="维修人员"></el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <span :style="'color:' + orderDealStatusColor(scope.row.is_deal)">
                {{ orderDealStatus(scope.row.is_deal) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="上报时间" width="200">
            <template slot-scope="scope">
              {{ $moment(scope.row.create_time).format("YYYY-MM-DD HH:mm:ss") }}
            </template>
          </el-table-column>
          <el-table-column label="操作" width="120">
            <template slot-scope="scope">
              <lbButton type="succes" icon="chakan" hint="查看" @click="lookBacklogOrder(scope.row.Id)"></lbButton>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryForm.PageIndex" :page-sizes="[10, 20, 30, 40]" :page-size="queryForm.PageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total">
        </el-pagination>
      </div>
    </le-card>
    <!-- 查看代办工单 -->
    <lebo-dialog title='查看代办工单' :isShow="showLookBacklogOrder" @close="showLookBacklogOrder = false" footerSlot closeOnClickModal>
      <lookBacklogOrder v-if="showLookBacklogOrder" :id="dialogLookBacklogOrderId" @closeDialog="closeBacklogOrder">
      </lookBacklogOrder>
    </lebo-dialog>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex'
import { getBacklogOrderList, delBacklogOrder } from '@/api/assetsManagement'
import lookBacklogOrder from './components/lookBacklogOrder/index.vue'
export default {
  components: { lookBacklogOrder },
  data () {
    return {
      date: '',
      // 查询表单
      queryForm: {
        start_time: '',
        end_time: '',
        exception_type: null, // 工单类型 1：巡检任务 2：视频巡检 3：事件上报 10:全部
        deal_status: null, // 状态 0：全部 1：待处理 2:已处理
        inspector_name: '',
        repairer_name: '',
        PageSize: 10,
        PageIndex: 1
      },
      // 数据列表
      tableData: [],
      total: 0,
      // 选中的数据
      multipleSelection: [],
      dialogLookBacklogOrderId: '',
      showLookBacklogOrder: false,
      // 表格插件配置项
      option: {
        searchMenuSpan: 8,
        header: false,
        delBtn: false, // 行删除按钮
        editBtn: false, // 编辑按钮
        selection: true,
        selectionFixed: false, // 解除选择框冻结
        // searchBtn:false,
        menuAlign: 'left', // 属性对齐方式
        menuHeaderAlign: 'left', // 表头对齐方式
        menu: true,
        menuFixed: false, // 解除操作框冻结
        tip: false,
        column: [
          {
            label: '异常说明',
            prop: 'exception_note'
          }, {
            label: '工单类型',
            prop: 'exception_type',
            slot: true
          }, {
            label: '巡检人员',
            prop: 'inspector_name'
          }, {
            label: '维修人员',
            prop: 'repairer_name'
          }, {
            label: '状态',
            prop: 'is_deal',
            slot: true
          }, {
            label: '上报时间',
            prop: 'create_time',
            slot: true
          }
        ]
      },
      // 分页器
      page: {
        background: false,
        total: 0,
        pageSize: 10,
        currentPage: 1
      }
    }
  },
  created () {
    if (this.queryStatus === 2) {
      this.queryForm = this.deepClone(this.queryObj)
    }
    this.fnGetBacklogOrderList()
  },
  mounted () {
    window.addEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  beforeRouteLeave (to, from, next) {
    this.setQueryObj(this.queryForm)
    this.setQueryStatus(1)
    next()
  },
  destroyed () {
    window.removeEventListener('beforeunload', e => this.beforeunloadFn(e))
  },
  computed: {
    ...mapState(['queryObj', 'queryStatus']),
    ...mapGetters(['getUserInfo', 'getOptions', 'getWayName'])
  },
  watch: {
    'date' (newV, oldV) {
      if (newV) {
        this.queryForm.start_time = newV[0]
        this.queryForm.end_time = newV[1]
      } else {
        this.queryForm.start_time = ''
        this.queryForm.end_time = ''
        this.onSubmit()
      }
    }
  },
  methods: {
    ...mapMutations(['setQueryObj', 'setQueryStatus']),
    beforeunloadFn (e) {
      this.setQueryStatus(1)
    },
    // 获取待办工单列表
    async fnGetBacklogOrderList () {
      const res = await getBacklogOrderList({
        start_time: this.queryForm.start_time,
        end_time: this.queryForm.end_time,
        exception_type: this.queryForm.exception_type,
        deal_status: this.queryForm.deal_status ? this.queryForm.deal_status : 0,
        inspector_name: this.queryForm.inspector_name,
        repairer_name: this.queryForm.repairer_name,
        PageSize: this.queryForm.PageSize,
        PageIndex: this.queryForm.PageIndex
      })
      // console.log(res);
      if (res && res.Code === 200) {
        this.tableData = res.Data.DataList
        this.total = res.Data.TotalCount
      } else {
        this.tableData = []
        this.total = 0
      }
      this.page.total = res && res.Code === 200 && res.Data ? res.Data.TotalCount : 0
    },
    // 查询
    onSubmit () {
      this.queryForm.PageIndex = 1
      this.fnGetBacklogOrderList()
    },
    // 每页条数改变
    handleSizeChange (val) {
      // console.log(`每页 ${val} 条`);
      this.queryForm.PageSize = val
      this.fnGetBacklogOrderList()
    },
    // // 每页条数改变
    // sizeChange (val) {
    //   this.page.currentPage = 1
    //   this.page.pageSize = val
    //   this.queryForm.PageSize = val
    //   this.fnGetBacklogOrderList()
    // },
    // // 页码改变
    // currentChange (val) {
    //   this.queryForm.PageIndex = val
    //   this.page.currentPage = val
    //   this.fnGetBacklogOrderList()
    // },
    // 页码改变
    handleCurrentChange (val) {
      this.queryForm.PageIndex = val
      this.fnGetBacklogOrderList()
      this.$refs.multipleTable.bodyWrapper.scrollTop = 0

      //  // console.log(`当前页: ${val}`);
    },
    // 当选择项发生变化时会触发该事件
    handleSelectionChange (val) {
      // console.log(val);
      this.multipleSelection = val
    },
    // // 选中的数据
    // selectionChange (list) {
    //   this.multipleSelection = list
    // },
    // 点击删除按钮
    batchDel () {
      if (this.multipleSelection.length > 0) {
        this.$confirm('此操作将永久删除选中的待办工单，您确定要删除选中的待办工单吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          var obj = {}
          var arr = []
          for (var i = 0; i < this.multipleSelection.length; i++) {
            arr.push(this.multipleSelection[i].Id)
            if (arr.length === this.multipleSelection.length) {
              obj.Id = arr.join(',')
              this.fnDelBacklogOrder(obj)
              // console.log('删除待办工单请求', obj);
            }
          }
        }).catch(() => {
          this.$msg.info('已取消删除')
        })
      } else {
        this.$msg.warning('请选择要删除的待办工单！')
      }
    },
    async fnDelBacklogOrder (obj) {
      const res = await delBacklogOrder(obj)
      // console.log(res);
      if (res && res.Code === 200) {
        this.$msg.success(res.Message)
      }
      this.fnGetBacklogOrderList()
    },
    lookBacklogOrder (id) {
      this.dialogLookBacklogOrderId = id
      this.showLookBacklogOrder = true
    },
    closeBacklogOrder () {
      this.showLookBacklogOrder = false
      this.fnGetBacklogOrderList()
    }
  }
}
</script>

<style scoped lang="less">
.queryBox {
  margin-bottom: 20px;

  /deep/.el-input{
    width: 80%!important;
  }
}
.el-form.query_form.el-form--inline .el-form-item.el-form-item--small{
    margin-right: 10px!important;
  }
 /deep/ .el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-date-editor{
    width: 345px!important;
  }
  /deep/.el-form.query_form.el-form--inline .el-form-item.el-form-item--small .el-form-item__content .el-input.el-input--small{
    width: 170px!important;
  }
</style>
