<template>
    <!-- 查看待办工单 -->
    <div class="box-card" style="text-align: left">
      <div class="title">异常详情</div>
      <el-divider></el-divider>
      <div class="detail_content">
        <div class="left_box">
          <el-form label-width="130px" class="detailForm">
            <el-form-item label="异常说明：">{{
              detailForm.exception_note
            }}</el-form-item>
            <el-form-item label="检查项：" v-if="detailForm.exception_type === 1"
              >{{ detailForm.inspection_name }}
            </el-form-item>
            <el-form-item
              label="巡检项目："
              v-if="detailForm.exception_type === 1"
              >{{ detailForm.project_name }}
            </el-form-item>
            <el-form-item
              label="巡检路线："
              v-if="detailForm.exception_type === 1"
              >{{ detailForm.route_name }}</el-form-item
            >
            <el-form-item label="巡检人员：">{{
              detailForm.inspector_name
            }}</el-form-item>
            <el-form-item label="上报时间：">{{
              $moment(detailForm.report_time).format("YYYY-MM-DD HH:mm:ss")
            }}</el-form-item>
          </el-form>
        </div>
        <div class="right_box">
          <el-image
            class="elImage"
            v-for="(item, index) in detailForm.exception_pic"
            :key="index"
            :src="item"
            :preview-src-list="detailForm.exception_pic"
            :z-index="9999"
          >
            <div slot="error" class="image-slot">
              <i class="iconfont lebo-picerr"></i>
              <span>加载失败</span>
            </div>
          </el-image>
        </div>
      </div>
      <div class="title">处理情况</div>
      <el-divider></el-divider>
      <div class="detail_content">
        <div class="left_box">
          <el-form label-width="130px" class="detailForm">
            <el-form-item label="指派时间："
              >{{
                $moment(detailForm.appoint_time).format("YYYY-MM-DD HH:mm:ss")
              }}
            </el-form-item>
            <el-form-item label="维修人员：">{{
              detailForm.repairer_name
            }}</el-form-item>
            <el-form-item label="处理时间：" v-if="detailForm.is_deal">
              {{ $moment(detailForm.deal_time).format("YYYY-MM-DD HH:mm:ss") }}
            </el-form-item>
            <el-form-item label="状态：">
              <span
                class="plan_status_box"
                :style="
                  detailForm.is_deal
                    ? 'background-color: #67C23A;'
                    : 'background-color: #E6A23C;'
                "
              >
                {{ detailForm.is_deal ? "已完成" : "待处理" }}
              </span>
            </el-form-item>
          </el-form>
        </div>
        <div class="right_box" v-if="detailForm.is_deal">
          <el-image
            class="elImage"
            v-for="(item, index) in detailForm.deal_pic"
            :key="index"
            :src="item"
            :preview-src-list="detailForm.deal_pic"
            :z-index="9999"
          >
            <div slot="error" class="image-slot">
              <i class="iconfont lebo-picerr"></i>
              <span>加载失败</span>
            </div>
          </el-image>
        </div>
      </div>
    </div>
  </template>

<script>
import { mapMutations } from 'vuex'
import { getBacklogOrderDetail } from '@/api/assetsManagement'
export default {
  props: ['id'],
  data () {
    return {
      detailForm: {}
    }
  },
  created () {
    this.fnGetBacklogOrderDetail()
  },
  methods: {
    ...mapMutations(['setQueryStatus']),
    // 返回
    goBack () {
      this.setQueryStatus(2)
      this.$router.go(-1)
    },
    // 获取工单详情
    async fnGetBacklogOrderDetail () {
      const res = await getBacklogOrderDetail({
        Id: this.id
      })
      if (res && res.Code === 200) {
        this.detailForm = res.Data.detail
        //  1：巡检任务 2：视频巡检 3：事件上报
        if (this.detailForm.exception_type === 1) {
          this.detailForm.project_name = res.Data.task.project_name
          this.detailForm.route_name = res.Data.task.route_name
          this.detailForm.inspection_name = res.Data.task.inspection_name
        }
        // this.detailForm = res.Data.detail;
        this.detailForm.exception_pic = this.detailForm.exception_pic
          ? this.detailForm.exception_pic.split(',')
          : []
        this.detailForm.deal_pic = this.detailForm.deal_pic
          ? this.detailForm.deal_pic.split(',')
          : []
      }
    }
  }
}
</script>

  <style scoped lang="less">
  .title {
    width: 100%;
    font-size: 18px;
    font-weight: 700;
    text-align: left;
    color: #000;
  }

  .detail_content {
    display: flex;
    justify-content: space-between;
    margin-bottom: 35px;

    .left_box {
      width: 75%;

      .detailForm {
        /deep/ .el-form-item {
          margin-bottom: 0;
        }
      }
    }

    .right_box {
      display: flex;
    //   width: 25%;
      text-align: right;
    }
  }

  .plan_status_box {
    padding: 5px 10px;
    color: #fff;
  }

  .elImage {
    width: 120px;
    height: 180px;
    margin-left: 15px;

    /deep/ .image-slot {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background-color: #f5f7fa;
      color: #999;
      .iconfont{
        font-size: 36px;
      }
    }

    /deep/ .el-image__inner {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
    }
  }
  </style>
